<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('suppForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-form ref="suppForm" :model="suppForm" :rules="rules" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="供应商编号" prop="supp_no">
            <!-- <el-input v-model="suppForm.supp_no" @input ="suppForm.supp_no = helper.keepEngNum1(suppForm.supp_no)" placeholder="请填写账套编号（仅支持数字和英文和-）" minlength="30"/> -->
            <el-input
              maxlength="10"
              v-model="suppForm.supp_no"
              @input="suppForm.supp_no = helper.keepEngNum2(suppForm.supp_no)"
              placeholder="请填写供应商编号"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商名称" prop="supp_name">
            <el-input v-model="suppForm.supp_name" maxlength="30" show-word-limit placeholder="请填写供应商名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商类型" prop="supp_type">
            <el-select v-model="suppForm.supp_type" placeholder="请选择供应商类型" size="mini" clearable show-word-limit>
              <el-option v-for="item in suppType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="联系人姓名" prop="liaison_name">
            <el-input v-model="suppForm.liaison_name" maxlength="30" show-word-limit placeholder="请填写联系人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="联系电话" prop="liaison_tel">
            <el-input
              v-model="suppForm.liaison_tel"
              @input="suppForm.liaison_tel = helper.keepNumberH(suppForm.liaison_tel)"
              maxlength="20"
              show-word-limit
              placeholder="请填写联系电话"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="主营类目" prop="supp_maincate">
            <el-select filterable v-model="suppForm.supp_maincate" placeholder="请选择主营类目" size="mini" clearable show-word-limit>
              <el-option v-for="item in mainBusinessList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import { optnAPI } from '@api/modules/optn';
import addHeader from '@/views/component/addHeader';
import helper from '@assets/js/helper';
import inputUser from '@/views/component/inputUser';

export default {
  name: 'SuppAddMain',
  components: {
    addHeader,
    inputUser
  },
  data() {
    return {
      rules: {
        supp_no: [{ required: true, trigger: 'blur', message: ' ' }],
        supp_name: [{ required: true, trigger: 'blur', message: ' ' }],
        supp_type: [{ required: true, trigger: 'change', message: ' ' }]
      },
      suppForm: {
        supp_no: null,
        supp_name: null,
        supp_type: null,
        supp_maincate: null
      },
      suppType: [
        { id: 1, label: '加工厂' },
        { id: 2, label: '面料厂' },
        { id: 3, label: '配套厂' },
        { id: 4, label: '辅料厂' }
      ],
      mainBusinessList: []
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getCompType();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      this.suppForm = Object.assign(this.suppForm, staffForm);
      post(suppAPI.addSupp, this.suppForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump(`/supp_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`);
            this.resetForm('suppForm');
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '供应商编号重复'
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('suppForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 获取成分
    getCompType() {
      get(optnAPI.getOptnByPermId, { perm_id: 10012 })
        .then(res => {
          if (res.data.code === 0) {
            this.mainBusinessList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_mrt1 {
  margin-top: 1px;
}
</style>
